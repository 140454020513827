export const DASHBOARD_TABS = {
  trajectory: 'Trajectory',
  dei: 'Score',
  momentum: 'Momentum',
  methodology: 'Methodology',
  countrydashboards:'Country Dashboards'
};

export const DEFAULT_TAB = 'trajectory';

export const DASHBOARD_TRUST_TABS = {
  overview: 'Overview',
  givers: 'Givers',
  guarantors: 'Guarantors',
  methodology: 'Methodology',
  trustcountrydashboards:'Country Dashboards'
};

export const DEFAULT_TRUST_TAB = 'overview';

export const TRUST_PIVOTS = {
  overview: [],
  givers: ['Attitudes', 'Behavior'],
  guarantors: ['Environment', 'Experience'],
  methodology: [],
  trustcountrydashboards: [],
};

export const PIVOTS = {
  trajectory: ['Overall', 'Supply', 'Demand', 'Institutions', 'Innovation'],
  dei: ['Overall', 'Supply', 'Demand', 'Institutions', 'Innovation'],
  momentum: ['Overall', 'Supply', 'Demand', 'Institutions', 'Innovation'],
  methodology: [],
  countrydashboards: [],
  ranking: ['Overall', 'Supply', 'Demand', 'Institutions', 'Innovation'],
  keydrivers: ['Overall'],
  trend: ['Overall', 'Supply', 'Demand', 'Institutions', 'Innovation']
};

export const DEFAULT_PIVOT_INDEX = 0;

export const VIEWS = {
  trajectory: [],
  dei: ['Map', 'List'],
  momentum: ['Map', 'List'],
  methodology: [],
  countrydashboards:[],
  ranking: ['State', 'Momentum'],
  keydrivers: ['State', 'Momentum'],
  trend: ['State', 'Momentum', 'StM']
};
export const DEFAULT_VIEW_INDEX = 0;

export const DEFAULT_MEDIAN_FILTER = 'WR';

// for country flags might change in future
export const DEFAULT_CDN_URL = 'https://flagcdn.com/';
export const DEFAULT_CDN_SUFFIX = 'svg';

// Details page specific items
export const DETAILS_TABS = {
  ranking: 'Ranking',
  keydrivers: 'Key Drivers',
  trend: 'Explore Trends'
};
export const DEFAULT_DETAILS_TAB = 'ranking';

export const SELECTION_COLORS = [
  '#C6B706',
  '#E31D00',
  '#0234D1',
  '#00BB9C',
  '#945D27'
];

export const PLOT_SHAPES = {
  Supply: 'square',
  Demand: 'invertedtriangle',
  Innovation: 'diamond',
  Institutions: 'triangle',
  Overall: 'circle'
};

export const PAGE_DESCRIPTORS = {
  trajectory:
  {
    overall: "An economy's digital trajectory is a function of two factors: its current state of digitalization (state) and its pace of digitalization over time (momentum).",
    supply: "Supply Conditions measure the quality and readiness of digital and physical infrastructure such as bandwidth availability and quality of roads.",
    demand: "Demand Conditions measure the adoption of digital technologies and behaviors, such as smartphone proliferation, internet usage, and e-commerce transactions.",
    institutions: "Institutional Environment tracks the performance of governments in taking deliberate steps towards advancing digitalization.",
    innovation: "Innovation and Change captures the dynamism of an economy’s innovation ecosystem, with measures on R&D spending, talent availability, and value capture."
  },
  dei: {
    overall: 'The Digital Evolution state tracks 160 indicators across four drivers: Demand Conditions, Supply Conditions, Institutional Environment, and Innovation and Change.',
    supply: 'Supply Conditions measure the quality and readiness of digital and physical infrastructure such as bandwidth availability and quality of roads.',
    demand: 'Demand Conditions measure the adoption of digital technologies and behaviors, such as smartphone proliferation, internet usage, and e-commerce transactions.',
    institutions: 'Institutional Environment tracks the performance of governments in taking deliberate steps towards advancing digitalization.',
    innovation: 'Innovation and Change captures the dynamism of an economy’s innovation ecosystem, with measures on R&D spending, talent availability, and value capture.'
  },
  momentum: {
    overall: "Momentum captures the relative growth rate of an economy's Digital Evolution overall state over a ten-year period. ",
    supply: 'Supply Conditions measure the quality and readiness of digital and physical infrastructure such as bandwidth availability and quality of roads.',
    demand: 'Demand Conditions measure the adoption of digital technologies and behaviors, such as smartphone proliferation, internet usage, and e-commerce transactions.',
    institutions: 'Institutional Environment tracks the performance of governments in taking deliberate steps towards advancing digitalization.',
    innovation: 'Innovation and Change captures the dynamism of an economy’s innovation ecosystem, with measures on R&D spending, talent availability, and value capture.'
  },

  methodology: {
    overall: 'Digital Evolution draws on data from over two dozen partners and publicly available sources to construct a composite picture of the digital economy.'
  },

  countrydashboards: {
    overall: ' Understand where countries stand across different indicators, with a better view of numbers behind the scores.'
  }
};

export const TRUST_PAGE_DESCRIPTORS = {
  methodology: {
    overall: 'Digital Trust is structured into 4 layers, aggregated up from 198 indicators to 37 clusters to 14 components, and finally into 4 drivers.'
  },

  overview: {
    overall: 'Digital Trust is the interplay between the attitudes and behaviors of trust \'givers\' and the environment and experience created by trust \'guarantors.\''
  },

  givers: {
    attitudes: 'How are citizens (givers of trust) feeling about their experiences with digitalization?',
    behavior: 'How do users (givers of trust) react and interact with the digital environment and experience?'
  },

  guarantors: {
    experience: 'Experience measures the quality of user experience in the digital trust environment.',
    environment: 'How are guarantors of trust performing in ensuring a secure online ecosystem?'
  },
  trustcountrydashboards: {
    overall: ' Understand where countries stand across different indicators, with a better view of numbers behind the scores.'
  }
};

export const TRUST_DESCRIPTION = 'Digital Trust is captured in the interplay between the Givers and Guarantors of Trust. Givers (users) exhibit trust through their Attitudes and Behavior. Guarantors (businesses and institutions) foster a trustworthy digital ecosystem through creating a seamless Experience and a safe, secure, and accountable Environment.';

export const captionsToBeShown = {
  innovation: 'Innovation',
  supply: 'Supply',
  institutions: 'Institutions',
  demand: 'Demand'
};

export const countrySelectorFullMessage =
  'You have already selected 5 economies for comparison. Remove any economy from the comparison list and try again.';

export const HOMECARDS_DATA = {
  standout: {
    heading: 'Stand Out Economies',
    content: 'Stand Out economies are both digitally advanced and exhibit high momentum.',

    quadrant: 'standout'
  },
  stallout: {
    heading: 'Stall Out Economies',
    content: 'Stall Out economies enjoy a high state of digital advancement while exhibiting slowing momentum.',
    quadrant: 'stallout'
  },
  watchout: {
    heading: 'Watch Out Economies',
    content: 'Watch Out economies face significant challenges with their low state of digitalization and low momentum.',
    quadrant: 'watchout'
  },
  breakout: {
    heading: 'Break Out Economies',
    content: 'Break Out economies are low scoring in their current states of digitalization but are evolving rapidly.',
    quadrant: 'breakout'
  }

};

export const LABELS = {
  FOOTER_MESSAGE: 'Digital Intelligence Index is a collaborative research initiative of The Fletcher School at Tufts University and Mastercard.',
  FOOTER_BUTTON_LABEL: 'DOWNLOAD REPORT',
  FOOTER_BUTTON_DATALABEL: 'DOWNLOAD DATA'
};

export const SIDEPANEL_MESSAGE = {
  givers: {
    attitudes: '<p>How do users feel about the digital trust environment?</p><ul><li>Sentiments</li><li>Confidence in Guarantors</li></ul>',
    behavior: '<p>How engaged are users in the digital environment?</p><ul><li>Digital Media Consumption</li><li>Tolerance of Friction</li><li>Payment Sophistication</li><li>Use of e-Commerce and Mobile Payments</li><li>Use of Social Media</li><li>Use of Technology</li></ul>'
  },
  guarantors: {
    environment: '<p>What are the mechanisms to build trust in the digital environment?</p><ul><li>Accountability</li><li>Privacy</li><li>Security</li></ul>',
    experience: '<p>How do users experience the digital trust environment?</p><ul><li>Access Friction</li><li>Infrastructural Friction</li><li>Interaction Friction</li></ul>'
  }
};

export const MEDIAN_MESSAGES = {
  WR: 'Global Median: Calculated from the 90 economies in the Digital Intelligence Index.',
  HM: 'High Income: The median of the High Income economies, as defined by the World Bank, within the Digital Intelligence Index.',
  UMM: 'Upper Middle Income: The median of the Upper Middle Income economies, as defined by the World Bank, within the Digital Intelligence Index.',
  LMM: 'Lower Middle Income: The median of the Lower Middle Income economies, as defined by the World Bank, within the Digital Intelligence Index.',
  LM: 'Low Income: The median of the Low Income economies within the DII, as defined by the World Bank, within the Digital Intelligence Index.',
  APM: 'Asia Pacific Median: The median of the Digital Intelligence Index economies within the Asia Pacific region.',
  ECAM: 'Europe and Central Asia Median: The median of the Digital Intelligence Index economies within Europe and Central Asia.',
  LACM: 'Latin America and Caribbean Median: The median of the Digital Intelligence Index economies within Latin America and the Caribbean.',
  MEAM: 'Middle East and Africa Median: The median of the Digital Intelligence Index economies within the Middle East and Africa.',
  NAM: 'North America Median: The median of the Digital Intelligence Index economies within North America.',
  SOM: 'Stand Out Median: The median of the Stand Out economies within the Digital Intelligence Index.',
  BOM: 'Break Out Median: The median of the Break Out economies within the Digital Intelligence Index.',
  WOM: 'Watch Out Median: The median of the Watch Out economies within the Digital Intelligence Index.',
  SLOM: 'Stall Out Median: The median of the Stall Out economies within the Digital Intelligence Index.'
};

export const COMPONENTS = [
  {
    pivot: "Overall",
    name: "",
    code: ""
  },
  {
    pivot: "Supply",
    name: "Supply Overall",
    code: "",
    message:"Supply Overall: Answers the question of how developed digital and business infrastructure is.",
    order: 0
  },
  {
    pivot: "Supply",
    name: "Access Infrastructure",
    code: "AI",
    message:"Access Infrastructure: The extent and quality of telecommunications infrastructure needed to get connected.",
    order: 1
  },
  {
    pivot: "Supply",
    name: "Fulfillment Infrastructure",
    code: "FI",
    message:"Fulfilment Infrastructure: The extent to which consumers and businesses can reliably and affordably order and deliver goods.",
    order: 3
  },
  {
    pivot: "Supply",
    name: "Transaction Infrastructure",
    code: "TI",
    message:"Transaction Infrastructure: The extent and quality of financial transaction means within a country.",
    order: 2    
  },
  {
    pivot: "Demand",
    name: "Demand Overall",
    code: "",
    message:"Demand Overall: Answers the question of whether consumers are willing to and able to transact in the digital environment.",
    order: 0
  },
  {
    pivot: "Demand",
    name: "State of the Human Condition",
    code: "SOHC",
    message:"State of Human Condition: The extent to which people have the means, skills, and willingness to engage online.",
    order:1
  },
  {
    pivot: "Demand",
    name: "Digital Inclusion",
    code: "DI",
    message:"Digital Inclusion: The extent to which inequities in digital account ownership, online engagement, and digital payment uptake exists across gender, class, and geography.",
    order: 4
  },
  {
    pivot: "Demand",
    name: "Device and Broadband Uptake",
    code: "DBU",
    message:"Device and Broadband Uptake: The extent to which people and households are connected to the internet and own digital devices.",
    order: 3
  },
  {
    pivot: "Demand",
    name: "Digital Payment Uptake",
    code: "DPU",
    message:"Digital Payment Uptake: The extent to which people have access to, and use, digital payment technologies, such as cards, or payments via mobile phone.",
    order: 2
  },
  {
    pivot: "Institutions",
    name: "Institutions Overall",
    code: "",
    message:"Institutions Overall: Answers the question as to whether government policies and regulations are facilitating the creation of digital ecosystems.",
    order: 0
  },
  {
    pivot: "Institutions",
    name: "Institutions and the Digital Ecosystem",
    code: "DE",
    message:"Institutions and the Digital Ecosystem: The extent to which institutions enable the use of, and themselves use, digital technologies.",
    order: 2
  },
  {
    pivot: "Institutions",
    name: "Institutions and the Business Environment",
    code: "BE",
    message:"Institutions and the Business Environment: The extent to which institutions enable business through appropriate legal protections, as well as minimizing unnecessary bureaucracy.",
    order: 1
  },
  {
    pivot: "Institutions",
    name: "Institutional Effectiveness and Trust",
    code: "ET",
    message:"Institutional Effectiveness and Trust: The extent to which institutions create an environment which enables trust.",
    order: 3
  },
  {
    pivot: "Innovation",
    name: "Innovation Overall",
    code: "",
    message:"Innovation Overall: Answers the question of to what extent new ideas and ventures are being created and adopted in digital commerce.",
    order: 0
  },
  {
    pivot: "Innovation",
    name: "Inputs",
    code: "IP",
    message:"Inputs: Inputs encompass elements needed to drive innovation and change, such as creating the right talent pool, having sufficient investment, and the creation of new ventures.",
    order: 1
  },
  {
    pivot: "Innovation",
    name: "Outputs",
    code: "OP",
    message:"Outputs: The extent to which new ideas, products, and systems are created, adopted, and exported.",
    order: 3
  },
  {
    pivot: "Innovation",
    name: "Processes",
    code: "PRC",
    message:"Processes: The extent to which there are systems in place which can facilitate the development of innovative ideas and practices.",
    order: 2
  }
];

export const EMAIL_SUBJECT = 'Digital Intelligence Index - Actionable insights on digital competitiveness';

export const EMAIL_BODY = `The Digital Intelligence Index, a collaborative research initiative of Fletcher, The Graduate School of Global Affairs, and Mastercard, provides evidence-driven actionable insights to leaders in government, business, and technology on how to enhance digital competitiveness, nurture trust in the digital economy, and foster responsible use of technologies for future proofing and the greater good. 
%0D%0A %0D%0ACheck out the interactive dashboard here: $URL
%0D%0A %0D%0A `;

export const TWITTER_SUBJECT = 'Check out the interactive dashboard on digital competitiveness of global economies.';
export const TWEET_HASHTAGS = 'DII,DigitalIntelligenceIndex,DigitalIntelligence,digitalcompetitiveness @dgtl_planet';
