import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

const Home = lazy(() => import('../Pages/Home/Home'));
const DetailsShell = lazy(() => import('../Pages/Details/DetailsShell'));
const NotFound = lazy(() => import('../Pages/NotFound'));
const Trust = lazy(() => import('../Pages/Trust/TrustHome'));

const MainRoutes = () => {
  const activeDetailRoutes = process.env.REACT_APP_ENABLETREND === 'true' ? 'ranking|keydrivers|trend' : 'ranking|keydrivers';

  return (
    <Suspense fallback={null}>
      <Switch>
        <Redirect exact from='/' to='/trajectory' />
        <Route
          path='/:tab(trajectory|dei|momentum|methodology|countrydashboards)/:pivot?/:view?'
          component={Home}
        />
        <Redirect
          exact
          from='/analyze/:country'
          to='/analyze/:country/ranking'
        />
        <Route
          path={`/analyze/:country/:tab(${activeDetailRoutes})/:pivot?/:view?/:median?/:component?`}
          component={({ history }) => (
            <DetailsShell history={history} type='analyze' />
          )}
        />
        <Redirect
          exact
          from='/compare/:country'
          to='/compare/:country/ranking'
        />
        <Route
          path={`/compare/:country/:tab(${activeDetailRoutes})/:pivot?/:view?/:median?/:component?`}
          component={({ history }) => (
            <DetailsShell history={history} type='compare' />
          )}
        />
        <Redirect
          exact
          from='/trust'
          to='/trust/overview'
        />
        <Route path='/trust/:tab(overview|givers|guarantors|methodology|trustcountrydashboards)/:pivot?' component={Trust} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default MainRoutes;
