import React from 'react';
import AppShell from './components/AppShell';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';
import 'typeface-raleway';
import 'typeface-open-sans';
import 'typeface-roboto';

function App () {
  return (
    <HelmetProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <AppShell />
        </BrowserRouter>
      </ErrorBoundary>
    </HelmetProvider>
  );
}

export default App;
