import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Social from './Social';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  headerCommon: {
    fontSize: '1.5rem!important',
    gridRow: 2,
    gridColumn: '1/3',
    '@global': {
      // locally scoped global selector.
      a: {
        // All (due to global) anchor tags inside of the parent selector (breadCrumbs) will have the styles applied
        textDecoration: 'none',
        color: theme.palette.breadcrumb.grey,
        '&:visited': {
          color: theme.palette.breadcrumb.grey
        },
        '&:hover': {
          color: theme.palette.trajectory.active
        }
      }
    }
  },
  activeTitle: {
    color: `${theme.palette.trajectory.active}!important`,
    fontWeight: 400
  },
  titleDivider: {
    color: theme.palette.grey[400],
    padding: theme.spacing(0, 1)
  },
  inactiveTitle: {
    fontFamily: 'Raleway',
    lineHeight: '2.7rem',
    textDecoration: 'none'
  },
  titleBar: {
    display: 'grid',
    padding: theme.spacing(1, 1),
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '1fr auto'
  },
  appTitle: {
    display: 'block',
    margin: theme.spacing(0.5, 0),
    gridRow: 1,
    gridColumn: 1,
    fontSize: '2rem!important'
  },
  socialTab: {
    gridRow: 1,
    gridColumn: 2
  }
}));

const TitleBar = () => {
  const classes = useStyles();

  return (
    <div className={classes.titleBar}>
      <h1 className={classes.appTitle}>Digital Intelligence Index</h1>
      <Typography variant='h2' className={classes.headerCommon}>
        <NavLink
          isActive={(match, location) => {
            return !location.pathname.startsWith('/trust');
          }}
          className={classes.inactiveTitle}
          activeClassName={classes.activeTitle}
          to='/'
        >
          Digital Evolution
        </NavLink>
        <span className={classes.titleDivider}> | </span>
        <NavLink
          className={classes.inactiveTitle}
          activeClassName={classes.activeTitle}
          to='/trust'
        >
          Digital Trust
        </NavLink>
      </Typography>
      <Social className={classes.socialTab} />
    </div>
  );
};
export default TitleBar;
