import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  color: {
    white: '#FFFFFF'
  },
  palette: {
    social: {
      facebook: '#3b5998',
      linkedIn: '#0072b1',
      twitter: '#1DA1F2'
    },
    quadrants: {
      standout: '#9B63F8',
      breakout: '#5AAB76',
      stallout: '#3BC8E2', // '#F89F37',
      watchout: '#E99729', // '#B81600',
      notAvailable: '#EAEAEA',
      notAvailableSelection: '#BCBCBC'
    },
    overview: {
      active: '#D45D00',
      selection: '#63B57F'
    },
    givers: {
      active: '#D45D00',
      selection: '#63B57F'
    },
    guarantors: {
      active: '#D45D00',
      selection: '#63B57F'
    },
    trajectory: {
      active: '#D45D00',
      selection: '#63B57F'
    },
    dei: {
      active: '#C556B8', // '#CC5A50', // '#E7185B',
      selection: '#63B57F'
    },
    momentum: {
      active: '#5DAEC0',
      selection: '#63B57F'
    },
    methodology: {
      active: '#047CFF',
      selection: '#047CFF'
    },
    countrydashboards: {
      active: '#3B7CF7',
      selection: '#3B7CF7'
    },
    
    trustcountrydashboards: {
      active: '#3B7CF7',
      selection: '#3B7CF7'
    },
    analyze: {
      active: '#555555',
      selection: '#555555'
    },
    footer: {
      mainBorder: '#D45D00',
      sectionBorder: '#F38A00',
      background: '#646469',
      foreground: '#ffffff',
      noteBackground: '#555555',
      noteBackgroundDark: '#333333',
      fontColor: '#ffffff'
    },
    country: {
      placeHolder: '#777777',
      subtitle: '#888888',
      countryName: '#555555',
      borderColor: '#DADADA'
    },
    legend: {
      empty: '#ffffff'
    },
    trustLegend: {
      min: '#ff6355',
      lowmedium: '#ffce80',
      highmedium: '#e0aaff',
      max: '#6b43a3'
    },
    breadcrumb: {
      active: '#1596EB',
      visited: '#1596EB',
      grey: '#888888'
    },
    forms: {
      borders: {
        hover: '#aaaaaa',
        focused: '#555555',
        active: '#eaeaea'
      }
    },
    text: {
      default: '#555555'
    },
    fletcherFooter: {
      background: '#002855',
      foreground: '#E56A17'
    }

  }
});

export default theme;
