import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import ShareIcon from '@material-ui/icons/Share';
import { IconButton, Menu, MenuItem, Hidden } from '@material-ui/core';
import { EMAIL_SUBJECT, EMAIL_BODY, TWITTER_SUBJECT, TWEET_HASHTAGS } from '../constants';

const useStyles = makeStyles((theme) => ({
  social: {
    display: 'flex',
    flex: '1 0 auto',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  socialButton: {
    display: 'flex',
    color: theme.palette.grey[800]
  },
  icon: {
    fontSize: '1.5rem'
  }
}));

const Social = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Hidden smUp>
        <div className={classes.social}>
          <IconButton
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            <ShareIcon title='Share' className={classes.icon} />
          </IconButton>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem>
              <IconButton
                aria-label='Share this page over Facebook'
                title='Share this page over Facebook'
                className={classes.socialButton}
                onClick={() => {
                  const shareTitle = document.title;
                  const shareUrl = window.location.href;
                  window.open(
                    `http://www.facebook.com/sharer.php?u=${shareUrl}&quote=${shareTitle}`
                  );
                  handleClose();
                }}
              >
                <FacebookIcon title='Facebook icon' className={classes.icon} />
              </IconButton>
            </MenuItem>
            <MenuItem>
              <IconButton
                aria-label='Share this page over Twitter'
                title='Share this page over Twitter'
                className={classes.socialButton}
                onClick={() => {
                  const shareTitle = TWITTER_SUBJECT;
                  const shareTags = TWEET_HASHTAGS;
                  const shareUrl = window.location.href;
                  window.open(
                    `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}&hashtags=${shareTags}`
                  );
                  handleClose();
                }}
              >
                <TwitterIcon title='Twitter icon' className={classes.icon} />
              </IconButton>
            </MenuItem>
            <MenuItem>
              <IconButton
                aria-label='Share this page over LinkedIn'
                title='Share this page over LinkedIn'
                className={classes.socialButton}
                onClick={() => {
                  // LinkedIn Sharing via URL does not have title
                  const shareUrl = window.location.href;
                  window.open(
                    `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`
                  );
                  handleClose();
                }}
              >
                <LinkedInIcon title='LinkedIn icon' className={classes.icon} />
              </IconButton>
            </MenuItem>
            <MenuItem>
              <IconButton
                aria-label='Share this page over Email'
                title='Share this page over Email'
                className={classes.socialButton}
                onClick={() => {
                  const shareTitle = EMAIL_SUBJECT;
                  const shareUrl = EMAIL_BODY.replace('$URL', window.location.href);
                  window.open(`mailto:?body=${shareUrl}&subject=${shareTitle}`);
                  handleClose();
                }}
              >
                <EmailIcon title='Email icon' className={classes.icon} />
              </IconButton>
            </MenuItem>
          </Menu>
        </div>
      </Hidden>
      <Hidden xsDown>
        <div className={classes.social}>
          <IconButton
            aria-label='Share this page over Facebook'
            title='Share this page over Facebook'
            className={classes.socialButton}
            onClick={() => {
              const shareTitle = document.title;
              const shareUrl = window.location.href;
              window.open(
                `http://www.facebook.com/sharer.php?u=${shareUrl}&quote=${shareTitle}`
              );
            }}
          >
            <FacebookIcon title='Facebook icon' className={classes.icon} />
          </IconButton>
          <IconButton
            aria-label='Share this page over Twitter'
            title='Share this page over Twitter'
            className={classes.socialButton}
            onClick={() => {
              const shareTitle = TWITTER_SUBJECT;
              const shareTags = TWEET_HASHTAGS;
              const shareUrl = window.location.href;
              window.open(
                `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}&hashtags=${shareTags}`
              );
            }}
          >
            <TwitterIcon title='Twitter icon' className={classes.icon} />
          </IconButton>
          <IconButton
            aria-label='Share this page over LinkedIn'
            title='Share this page over LinkedIn'
            className={classes.socialButton}
            onClick={() => {
              // LinkedIn Sharing via URL does not have title
              const shareUrl = window.location.href;
              window.open(
                `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`
              );
            }}
          >
            <LinkedInIcon title='LinkedIn icon' className={classes.icon} />
          </IconButton>
          <IconButton
            aria-label='Share this page over Email'
            title='Share this page over Email'
            className={classes.socialButton}
            onClick={() => {
              const shareTitle = EMAIL_SUBJECT;
              const shareUrl = EMAIL_BODY.replace('$URL', window.location.href);
              window.open(`mailto:?body=${shareUrl}&subject=${shareTitle}`);
            }}
          >
            <EmailIcon title='Email icon' className={classes.icon} />
          </IconButton>
        </div>
      </Hidden>
    </>
  );
};

export default Social;
