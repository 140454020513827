import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(3)
  },
  title: {
    fontSize: '3rem',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  }
}));
const ErrorPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='h1' className={classes.title}>
        Something went wrong...
      </Typography>
      <Button component='a' variant='outlined' href='/'>
        Back to Home
      </Button>
    </div>
  );
};
export default ErrorPage;
