import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MainRoutes from './MainRoutes';
import theme from '../theme';
import {
  createStyles,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import { Helmet } from 'react-helmet-async';
import TitleBar from './TitleBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 0 auto',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: '100vh'
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  })
);
export const notify = (id, message) => {
  toast.warn(message, {
    toastId: id
  });
};

const AppShell = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet
        titleTemplate='%s'
        defaultTitle='Digital Intelligence Index - a study of global digital competitiveness'
      />
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Header />
          <TitleBar className={classes.titleBar} />
          <div className={classes.content}>
            <MainRoutes />
          </div>
          <Footer />
        </div>
        <ToastContainer
          position='bottom-center'
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
        />
      </ThemeProvider>
    </>
  );
};

export default AppShell;
