import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../Pages/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError (error) {
    return { error };
  }

  render () {
    const { error } = this.state;
    return error ? <ErrorPage error={error} /> : this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node
};
export default ErrorBoundary;
