import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MastercardLogo from '../images/mscard.png';
import DigitalPlanetLogo from '../images/DigitalPlanetLogo.gif';
import { Link } from '@material-ui/core';
//import FletcherLogoOld from '../images/FletcherLogo.svg';
//import FletcherLogoNew from '../images/FletcherLogo-new.svg';

//let FletcherLogo = FletcherLogoOld;
//if (process.env.REACT_APP_USE_NEWLOGO === 'true') { FletcherLogo = FletcherLogoNew; }

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 1px 1px 0px #cacaca'
  },

  title: {
    gridRow: 1,
    gridColumn: 1,
    padding: 0
  },

  logo: {
    height: '60px',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },

  fletcherLogo: {
    height: '40px',
    paddingRight: theme.spacing(1)
  },

  ["@media (max-width: 850px)"]:
  {
    fletcherLogo: {
      zoom: 0.5
    }
  },

  mscardLogo: {
    height: '45px',
    paddingRight: theme.spacing(4)
  },

  toolbarGrid: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    placeItems: 'left',
    alignItems: 'center'
  }
}));

const Header = () => {
  const classes = useStyles();
  return (
    <AppBar
      position='static'
      color='transparent'
      elevation={1}
      height={80}
      classes={{ root: classes.root }}
    >
      <Toolbar className={`${classes.toolbarGrid} headerZoomClass`}>
        <div className={classes.title}>
          <Link href='https://digitalplanet.tufts.edu/'>
            <img
              src={DigitalPlanetLogo}
              className={classes.logo}
              alt='Digital Planet logo'
              title='Digital Planet'
            ></img>
          </Link>
        </div>
        <div>
          <Link href='https://www.mastercard.com'>
            <img
              src={MastercardLogo}
              className={classes.mscardLogo}
              alt='Mastercard logo'
              title='Mastercard'
            ></img>
          </Link>
        </div>
        <div>
          <Link href='https://fletcher.tufts.edu/'>
            <img
              src='https://digitalplanet.tufts.edu/wp-content/uploads/2023/11/The-Fletcher-School-Logo-Clr.png'
              className={classes.fletcherLogo}
              alt='The Fletcher School logo'
              title='The Fletcher School'
            ></img>
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
