import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Link, Paper, Hidden } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { LABELS } from '../constants';

const useStyles = makeStyles((theme) => ({
  tuftsUniversalFooter: {
    textAlign: 'center'
  },
  footerNote: {
    backgroundColor: theme.palette.footer.noteBackground,
    color: theme.palette.footer.foreground,
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: '1fr',
    gridColumnGap: theme.spacing(1.5),
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '0.875rem',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1, 2),
    '@media only screen and (max-width: 1024px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto'
    }
  },

  footerNote2: {
    backgroundColor: theme.palette.footer.noteBackgroundDark,
    color: theme.palette.footer.foreground,
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '1fr',
    gridColumnGap: theme.spacing(1.5),
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '0.875rem',
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(1, 2),
    '@media only screen and (max-width: 1024px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto'
    },
    '& a': {
      textDecoration: 'none',
      color: '#1596EB'
    },
    '& a:hover': {
      backgroundColor: theme.palette.grey[800]
    }
  },

  footerNoteBtn: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.trajectory.active,
    '@media only screen and (max-width: 1024px)': {
      justifySelf: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[800]
    }
  },

  siteFooter: {

    backgroundColor: theme.palette.footer.background,
    color: theme.palette.footer.fontColor,
    textAlign: 'left',
    alignItems: 'top',
    padding: theme.spacing(3, 0, 0, 0),
    borderTop: `1px solid ${theme.palette.footer.mainBorder}`,
    margin: theme.spacing(0)
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.footer.sectionBorder}`,
    backgroundColor: 'inherit',
    color: 'inherit',
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(4)
  },
  siteLogo: {
    alignSelf: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  socialLinks: {
    marginTop: theme.spacing(0.25) // changed
  },

  footerElementHeader: {
    fontFamily: 'Open Sans',
    fontSize: '0.9rem',
    color: '#b5b5b5',
    fontWeight: 400
  },

  footerLink: {
    fontFamily: 'Open Sans',
    fontSize: '0.75rem',
    color: '#ffffff',
    fontWeight: 400,
    marginBottom: theme.spacing(1.25),
    display: 'block'
  },

  iconContainer: {
    backgroundColor: '#d45d00',
    height: '32px',
    width: '32px',
    display: 'inline-grid',
    placeItems: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  tuftsUniversalFooterLink: {
    color: '#1bb0ce',
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingBottom: theme.spacing(2)
  },
  emptySpace: {
    padding: theme.spacing(4)
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.footerNote}>
        <p>
          { LABELS.FOOTER_MESSAGE}
        </p>
        <div>
          <Button
            component={Link}
            className={classes.footerNoteBtn}
            variant='contained'
            color='inherit'
            href={process.env.REACT_APP_REPORT_URL}
            target='_blank'
          >
            { LABELS.FOOTER_BUTTON_LABEL }
          </Button>
          <Button
          component={Link}
          className={classes.footerNoteBtn}
          variant='contained'
          color='inherit'
          href={process.env.REACT_APP_REPORT_DATA_URL}
          target='_blank'
        >
          { LABELS.FOOTER_BUTTON_DATALABEL }
        </Button>
        </div>
      </div>
      <div className={classes.footerNote2}>
        <p>
        For media queries, contact Kenneth Craig at <a href='mailto:kcraig@greenough.biz'>kcraig@greenough.biz</a>.
        For more details about the research, write to <a href='mailto:digitalplanet@tufts.edu'>digitalplanet@tufts.edu</a>.
        </p>
      </div>
      <Grid container className={classes.siteFooter} spacing={0}>
        <Grid item xs={12} sm={6} md={3} className={classes.siteLogo}>
          <Link href='https://fletcher.tufts.edu/'>
            <img
              src= 'https://digitalplanet.tufts.edu/wp-content/uploads/2023/11/The-Fletcher-School-Logo-White.png'
              title='Fletcher Logo'
              alt='Fletcher Logo White'
              width='220px'
            />
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.borderTop} elevation={0} square>
            <h3 className={classes.footerElementHeader}>Quick Links</h3>
            <div>
              <Link
                className={classes.footerLink}
                color='inherit'
                href='https://fletcher.tufts.edu/'
              >
                The Fletcher School
              </Link>

              <Link
                className={classes.footerLink}
                color='inherit'
                href='https://www.tufts.edu/'
              >
                Tufts University
              </Link>

              <Link
                className={classes.footerLink}
                color='inherit'
                href='https://sites.tufts.edu/ibgc/'
              >
                Institute for Business in the Global Context
              </Link>

              <Link
                className={classes.footerLink}
                color='inherit'
                href='https://fletcher.tufts.edu/academics/masters-programs-residential/MIB'
              >
                Master of International Business
              </Link>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.borderTop} elevation={0} square>
            <h3 className={classes.footerElementHeader}>Contact </h3>

            <div>
              <Link
                className={classes.footerLink}
                color='inherit'
                href='https://digitalplanet.tufts.edu/contact-us/'
              >
                Join Our Mailing List
              </Link>

              <Link
                className={classes.footerLink}
                color='inherit'
                href='mailto:kcraig@greenough.biz'
              >
                Media Inquiries
              </Link>

              <div>__________</div>
              <p className={classes.footerLink}>
                160 Packard Avenue, Medford, MA 02155 USA
              </p>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper className={classes.borderTop} elevation={0} square>
            <h3 className={classes.footerElementHeader}>Social Media</h3>
            <div>
              <div className={classes.iconContainer}>
                <Link
                  className={classes.socialLinks}
                  color='inherit'
                  href='https://www.facebook.com/fletcherschool/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FacebookIcon />
                </Link>
              </div>
              <div className={classes.iconContainer}>
                <Link
                  className={classes.socialLinks}
                  color='inherit'
                  href='https://twitter.com/dgtl_planet'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <TwitterIcon />
                </Link>
              </div>
              <div className={classes.iconContainer}>
                <Link
                  className={classes.socialLinks}
                  color='inherit'
                  href='https://instagram.com/digitalplanetdispatches/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <InstagramIcon />
                </Link>
              </div>
              <div className={classes.iconContainer}>
                <Link
                  className={classes.socialLinks}
                  color='inherit'
                  href='https://www.youtube.com/watch?v=7iIkQYtltoY&list=PLDU-EzkKlB6m3FvP3DwLsih-Jxplemf2X&pp=iAQB'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <YouTubeIcon />
                </Link>
              </div>
              <div className={classes.iconContainer}>
                <Link
                  className={classes.socialLinks}
                  color='inherit'
                  href='https://www.linkedin.com/company/digital-planet-tufts/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <LinkedInIcon />
                </Link>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <div>
        <div
          id='tufts_universal_footer'
          className={classes.tuftsUniversalFooter}
        >
          <Link
            className={classes.tuftsUniversalFooterLink}
            href='https://sites.tufts.edu/terms-of-use/content-disclaimer/'
          >
            Disclaimer
          </Link>
          |
          <Link
            className={classes.tuftsUniversalFooterLink}
            href='https://oeo.tufts.edu/policies-procedures-1/non-discrimination-statement/'
          >
            Non-Discrimination
          </Link>
          |
          <Link
            className={classes.tuftsUniversalFooterLink}
            href='https://www.tufts.edu/about/privacy'
          >
            Privacy
          </Link>
          |
          <Link
            className={classes.tuftsUniversalFooterLink}
            href='https://sites.tufts.edu/terms-of-use/'
          >
            Terms for Creating and Maintaining Sites
          </Link>
        </div>
        <Hidden smUp>
          <div className={classes.emptySpace} />
        </Hidden>
      </div>
    </div>
  );
};

export default Footer;
